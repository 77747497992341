// extracted by mini-css-extract-plugin
export const dialog = "NewsletterDialog-module--dialog--3kDrC";
export const wrapper = "NewsletterDialog-module--wrapper--2Cxt5";
export const container = "NewsletterDialog-module--container--3shaD";
export const icon = "NewsletterDialog-module--icon--fyPGl";
export const row = "NewsletterDialog-module--row--3lyah";
export const textContainer = "NewsletterDialog-module--textContainer--3kyiv";
export const title = "NewsletterDialog-module--title--3Wcf2";
export const decscription = "NewsletterDialog-module--decscription--3x3gz";
export const notice = "NewsletterDialog-module--notice--1LKYH";
export const smallPrint = "NewsletterDialog-module--smallPrint--2tY78";